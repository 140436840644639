import React, { useLayoutEffect } from "react";
// @ts-ignore
import { graphql, PageProps } from "gatsby";
import Helmet from "react-helmet";
import { withPrefix, navigate } from "gatsby";
import { LocalisedPageContext } from "../../types/PageTypes";
import StructuredData from "../../components/StructuredData";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import { ImageInterface, LocalizedContextInterface, Seo, SanityColorList } from "../../types/SanityTypes";
import HeroBanner from "../../components/HeroBanner";
import TabsBlock from "../../components/TabsBlock";
import { getAuthUser } from "../../services/salesforce/Auth";

type ProfilePageQueryProps = {
  profilePage: {
    _id: string;
    banner: {
      heading: string;
      subHeading?: string;
      contentAlignment: string;
      _rawHeroImage: ImageInterface;
      _rawHeroVideo: any;
      primaryColor: SanityColorList;
      isVideoBanner: boolean;
    };
    seo: Seo;
    slug: {
      current: string;
    };
    tabs: {
      heading?: string;
      description?: string;
      bgColor?: {
        title: string;
      };
      tabs: any[];
    };
  };
} & LocalizedContextInterface;

type ProfilePageContext = LocalisedPageContext;

export const query = graphql`
  query ($_id: String, $language: String) {
    profilePage: sanityProfilePage(_id: { eq: $_id }) {
      ...ProfilePageFieldsFull
    }
    ...LocalizedContext
  }
`;

const ProfilePage = (props: PageProps<ProfilePageQueryProps, ProfilePageContext>) => {
  const language = props.pageContext.language;
  const user = getAuthUser();

  useLayoutEffect(() => {
    if (!user) {
      navigate("/");
    }
  });

  const profilePage = props.data.profilePage;

  const { htmlLang, siteUrl } = useSiteMetadata(language);

  const pageTitle = profilePage?.banner?.heading;
  const localizedPath = withPrefix(getLocalizedPath(profilePage.slug?.current, profilePage._id));
  const tabs = profilePage.tabs;

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={pageTitle}
      pageType={"contentPage"}
      analyticsTagMeta={{ promoId: "942" }}
    >
      <StructuredData
        type={"ContentPage"}
        data={profilePage.banner._rawHeroImage}
        language={htmlLang}
        slug={`/${profilePage.slug.current}/`}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <SEO
        title={profilePage.seo.metaTitle}
        description={profilePage.seo.metaDescription}
        imageUrl={profilePage.banner._rawHeroImage.asset.url}
        imageAlt={profilePage.banner._rawHeroImage.alt}
      />
      <div className="user-profile-page-us">
        <HeroBanner
          sanityImage={profilePage.banner._rawHeroImage}
          title={profilePage.banner.heading}
          alignment={profilePage.banner.contentAlignment}
          isVideoBanner={profilePage.banner.isVideoBanner}
          video={profilePage.banner._rawHeroVideo}
        />
        <TabsBlock heading={tabs.heading} description={tabs.description} bgColor={tabs.bgColor} tabs={tabs.tabs} />
      </div>
    </Layout>
  );
};

export default ProfilePage;
