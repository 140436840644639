import React, { FunctionComponent, useContext, useEffect, useState, useRef } from "react";
import { navigate } from "gatsby";
import { QuoteBlockInterface } from "../QuoteBlock";
import { TextEditorBlockInterface } from "../TextEditorBlock";
import Section from "../Section";
import "./styles.scss";
import BlockRenderer from "../BlockRenderer";
import useMediaQuery from "../../hooks/useMediaQuery";
import { ProfileDetailsInterface } from "../ProfileDetails";
import EcommerceContext from "../../services/EcommerceContextService";
import { PortableText } from "../../types/SanityTypes";
import RichText from "../RichText";

export type TabProps = {
  tabTitle: string;
  tabContent: TabContentProps[];
};

export type TabContentProps = QuoteBlockInterface | TextEditorBlockInterface | ProfileDetailsInterface;

interface TabsBlocklInterface {
  heading?: string;
  _rawDescription?: PortableText;
  bgColor?: {
    title: string;
  };
  tabs: TabProps[];
  initialActiveTab?: number;
}

const TabsBlock: FunctionComponent<TabsBlocklInterface> = props => {
  const { heading, _rawDescription, bgColor, tabs, initialActiveTab = 0 } = props;
  const bgClassName = bgColor?.title || "default";
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [tabExpand, setTabExpand] = useState<boolean>(false);

  const mobileTabToggle = () => {
    tabExpand ? setTabExpand(false) : setTabExpand(true);
  };
  const stickyElement = useRef<HTMLDivElement>(null);
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    if (stickyElement.current && isMobile) {
      const positionFromTop = stickyElement.current.getBoundingClientRect().top;
      if (positionFromTop <= 5) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  });

  const scrollIntiView = () => {
    if (isMobile && stickyElement && stickyElement.current) {
      stickyElement.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { isUserAuthenticated } = useContext(EcommerceContext);

  return (
    <>
      {!isUserAuthenticated && typeof window !== "undefined" && window.location.pathname.match(`/profile`) ? (
        navigate("/login")
      ) : (
        <Section title={heading} theme={bgClassName} sectionClassName="c-tabs">
          {_rawDescription ? (
            <div className="c-tabs-description">
              <RichText data={_rawDescription} />
            </div>
          ) : null}
          <div className="c-tabs-wrapper" ref={stickyElement}>
            {isMobile ? (
              <div className={`c-tabs-accordion__container  ${sticky ? "tab-sticky" : ""}`}>
                <div className="c-tabs-accordion__wrapper">
                  <div className={`c-tabs-accordion__tab ${tabExpand ? "open" : ""} `}>
                    <button
                      className="c-tabs-accordion__tab-active"
                      onClick={mobileTabToggle}
                      aria-expanded={tabExpand}
                    >
                      {tabs[activeTab].tabTitle}
                    </button>
                  </div>

                  <div className={`c-tabs-accordion__panel ${tabExpand ? "show" : ""}`}>
                    <ul className="c-tabs__tablist" role="tablist" aria-orientation="vertical">
                      {tabs.map((tab: TabProps, index: number) => (
                        <li className={`c-tabs__tab  ${index == activeTab ? "active-tab" : ""}`} key={index}>
                          <a
                            key={`tab-${index}`}
                            className={`c-tabs__tab-link`}
                            href="#"
                            onClick={event => {
                              event.preventDefault();
                              setActiveTab(index);
                              setTabExpand(false);
                              scrollIntiView();
                            }}
                            role="tab"
                            aria-controls={`panel-id-${index}`}
                            aria-selected={activeTab === index}
                            id={`tab-id-${index}`}
                          >
                            {tab.tabTitle}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <ul className="c-tabs__tablist spb-md-64 spt-md-64" role="tablist" aria-orientation="vertical">
                {tabs.map((tab: TabProps, index: number) => (
                  <li className={`c-tabs__tab ${index == activeTab ? "active-tab" : ""}`} key={index}>
                    <a
                      key={`tab-${index}`}
                      className={`c-tabs__tab-link`}
                      href="#"
                      onClick={event => {
                        event.preventDefault();
                        setActiveTab(index);
                      }}
                      role="tab"
                      aria-controls={`panel-id-${index}`}
                      aria-selected={activeTab === index}
                      id={`tab-id-${index}`}
                    >
                      {tab.tabTitle}
                    </a>
                  </li>
                ))}
              </ul>
            )}

            <div className={`c-tabs-content ${sticky ? "sticky-tab" : ""}`}>
              {tabs.map((tab: TabProps, index: number) => (
                <div
                  key={`tabpanel-${index}`}
                  className="c-tabs-content__panel"
                  hidden={activeTab !== index}
                  role="tabpanel"
                  aria-labelledby={`tab-id${index}`}
                  id={`panel-id-${index}`}
                >
                  {tab.tabContent.length &&
                    tab.tabContent.map((item: TabContentProps, index: number) => (
                      <div key={index}>
                        <BlockRenderer section={item} index={index} />
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
        </Section>
      )}
    </>
  );
};

export default TabsBlock;
